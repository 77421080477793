// https://ironodata.info/rgb.php?color=7B8D42
export const baseColors = {
    MAIN: "#7B8D42",
    SECONDARY: "#427B8C",
    ACCENT: "#8C427B",
    BLACK: "#323232",
    GARY: "#969696",
    LIGHT_GARY: "#E1E1E1",
    WHITE: "#FFF",
} as const

export const textColors = {
    PRIMARY: baseColors.BLACK,
    SECONDARY: baseColors.GARY,
    TOUCHABLE: baseColors.SECONDARY,
    DISABLED: baseColors.LIGHT_GARY,
    ATTENTION: baseColors.ACCENT,
} as const
