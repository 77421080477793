import React from "react";
import { css } from "@emotion/react";
export const Footer: React.FC = () => (
  <footer>
    <p css={footerStyle}>© {new Date().getFullYear()} Hayashi</p>
  </footer>
);

const footerStyle = css({
  fontSize: "14px",
  margin: "0px",
});
