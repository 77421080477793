import React, { useMemo } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { css } from "@emotion/react";

import "@fontsource/noto-sans-jp";
import { baseColors } from "../../util/styleConst";
import { Footer } from "./atoms/Footer";
import { LayoutQuery } from "../../types/graphql-types";

type Props = {
  location: Location;
};

export const Layout: React.FC<Props> = ({ location, children }) => {
  const data: LayoutQuery = useStaticQuery(
    graphql`
      query Layout {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  );
  // @ts-expect-error
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  const isAbout = location.pathname === `${rootPath}/about`;
  const title = data?.site?.siteMetadata?.title;

  const headerContent = useMemo(
    () => (
      <div css={headerTextContainerStyle}>
        <h1 css={headTextStyle}>
          <Link to="/">{title}</Link>
        </h1>
        <h4>
          <Link css={[headTextStyle, aboutTextStyle]} to="/about">
            About
          </Link>
        </h4>
      </div>
    ),
    [],
  );

  return (
    <div data-is-root-path={isRootPath} css={topLayout}>
      <header css={headerStyle}>{headerContent}</header>
      <section css={bodyStyle}>
        <main>{children}</main>
        <Footer />
      </section>
    </div>
  );
};

const topLayout = css({
  display: "flex",
  flexDirection: "column",
  backgroundColor: baseColors.WHITE,
  minHeight: "100vh",
  margin: "0 30px",
});

const bodyStyle = css({
  margin: "0 auto",
  padding: "0",
  maxWidth: "800px",
});

const headerStyle = css({
  backgroundColor: baseColors.WHITE,
  marginBottom: "30px",
  display: "flex",
  justifyContent: "center",
});

const headerTextContainerStyle = css({
  maxWidth: "800px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
});

const headTextStyle = css({
  color: baseColors.MAIN,
  textAlign: "left",
  alignSelf: "flex-end",
});

const aboutTextStyle = css({
  display: "block",
});
